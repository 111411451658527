@media print {
    /* Basic
     * mostly from https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css
     */
    *,
    *:before,
    *:after,
    p:first-letter,
    div:first-letter,
    blockquote:first-letter,
    li:first-letter,
    p:first-line,
    div:first-line,
    blockquote:first-line,
    li:first-line {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
        font-size: 90%;
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
        font-size: 90%;
    }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
    .logo a:after,
    .contact__direct a:after,
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    /* Custom
    */
    .body,
    body {
      background: transparent none;
      overflow: hidden;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    a,
    span {
        background: transparent none !important;
        color: black !important;
        box-shadow: none !important;
        word-wrap: break-word;
    }

    h1 {
        font-size: 24pt;
    }

    h2 {
        font-size: 14pt;
        margin-top: 25px;
    }

    p {
        font-size: 12pt;
    }

    .mobile-nav,
    .mobile-nav__trigger,
    .footer__nav,
    .footer__left,
    .search,
    .most_searched,
    .slider,
    .contact,
    .search-results__filterbox,
    .pagination,
    #socialshareprivacy,
    .wagtail-userbar-nav {
        display: none !important;
    }

    img {
        max-width: 500px;
    }

}
